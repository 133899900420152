@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom base styles */
@layer base {
  html {
    font-family: 'DM Sans', system-ui, sans-serif;
  }
}

/* Custom component styles */
@layer components {
  .btn-primary {
    @apply bg-pickle-purple text-white px-8 py-3 rounded-md hover:bg-opacity-90 transition-colors duration-200;
  }
  
  .input-primary {
    @apply px-4 py-3 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-pickle-purple focus:border-transparent;
  }
}